// import icons
import { FaYoutube, FaInstagram, FaGithub } from "react-icons/fa"
import { BsChatDotsFill } from "react-icons/bs"

// import images
import AboutImg from "../src/assets/img/about.png"
import Feature1Img from "../src/assets/img/features/f1.png"
import Feature2Img from "../src/assets/img/features/f2.png"
import Feature3Img from "../src/assets/img/features/f3.png"
import Feature4Img from "../src/assets/img/features/f4.png"
import Feature5Img from "../src/assets/img/features/f5.png"
import Feature6Img from "../src/assets/img/features/f6.png"
import Feature7Img from "../src/assets/img/features/f7.png"
import Feature8Img from "../src/assets/img/features/f8.png"
import Feature9Img from "../src/assets/img/features/f9.png"

// Download Buttons
import AppleBtn from "./assets/AppleBtn.png"
import GoogleBtn from "./assets/GoogleBtn.png"
import TwoPhones from "./assets/TwoPhones.png"

import Avatar1Img from "../src/assets/img/testimonials/avatar1.png"
import Avatar2Img from "../src/assets/img/testimonials/avatar2.png"
import Avatar3Img from "../src/assets/img/testimonials/avatar3.png"
import LogoV2 from "../src/assets/img/logo-v2.png"
import HeroImage from "../src/assets/img/BgPhone.png"
import Feature1BgImg from "../src/assets/img/features/feature1_bg.png"
import Feature2BgImg from "../src/assets/img/features/feature2_bg.png"
import Feature3BgImg from "../src/assets/img/features/feature3_bg.png"
import Feature4BgImg from "../src/assets/img/features/feature4_bg.png"

export const navigationData = [
 {
  name: "Features",
  href: "#features",
  isExternal: false,
 },
 {
  name: "Privacy",
  href: "https://api.medysense.app/terms-and-conditions/",
  isExternal: true,
 },
 {
  name: "FAQs",
  href: "#",
  isExternal: false,
 },
 {
  name: "Support",
  href: "https://api.medysense.app/privacy-policy/",
  isExternal: true,
 },
]

export const heroData = {
 title: `Find and Book Medical Professionals`,
 subtitle:
  "Our AI-driven platform connects you with nearby medical professionals, provides real-time tracking, and offers on-site diagnosis. Take control of your health with just one click. Welcome to the future of healthcare with Medysense.",
 btnText: "Learn more",
 image: HeroImage,
}

export const aboutData = {
 image: AboutImg,
 title: "We are a high-level data storage bank",
 subtitle:
  "The place to store various data that you can access at any time through the internet  and where you can carry it. This very flexible storage area has a high level of security. To enter into your own data you must enter the password that you created when you registered in this Data Warehouse.",
}

export const featuresData = {
 title: "Health care at your comfort zone",
 subtitle:
  "We are revolutionizing the way you access healthcare services. Say goodbye to long wait times, crowded hospitals, and the inconvenience of finding the right doctor.",
 list: [
  {
   image: Feature1Img,
   bgImage: Feature1BgImg,
   title: "AI-Powered Matching",
   description:
    "Describe how you feel, We use the power of AI to match you with nearby medical professionals based on your symptoms or health concerns..",
   linkText: "Learn more",
   delay: "200",
  },
  {
   image: Feature2Img,
   bgImage: Feature2BgImg,
   title: "Convenient Meeting",
   description:
    "You have the flexibility to choose where you'd like to meet, making healthcare accessible and convenient for you.",
   linkText: "Learn more",
   delay: "200",
  },
  {
   image: Feature3Img,
   bgImage: Feature3BgImg,
   title: "Real-Time Tracking",
   description:
    "You can track the progress of the doctor en route to your chosen meeting place in real-time.",
   linkText: "Learn more",
   delay: "200",
  },
  {
   image: Feature4Img,
   bgImage: Feature4BgImg,
   title: "On-Site Diagnosis",
   description:
    "Our medical professionals can provide on-site diagnoses, ensuring you receive immediate care.",
   linkText: "Learn more",
   delay: "200",
  },
  {
   image: Feature5Img,
   bgImage: Feature4BgImg,
   title: "Doctor Profiles",
   description:
    "Explore detailed profiles of medical professionals, including their qualifications and specialties, so you can make informed choices.",
   linkText: "Learn more",
   delay: "200",
  },
  {
   image: Feature6Img,
   bgImage: Feature4BgImg,
   title: "Secure and Private",
   description:
    "We prioritize your privacy, ensuring secure and private communication for your peace of mind.",
   linkText: "Learn more",
   delay: "200",
  },
  {
   image: Feature7Img,
   bgImage: Feature1BgImg,
   title: "Feedback and Reviews",
   description:
    "You have the opportunity to leave reviews and feedback about your experiences, promoting trust and transparency.",
   linkText: "Learn more",
   delay: "200",
  },
  {
   image: Feature8Img,
   bgImage: Feature2BgImg,
   title: "Scheduling",
   description:
    "Easily book appointments with medical professionals in advance, ensuring timely care when you need it.",
   linkText: "Learn more",
   delay: "200",
  },
  {
   image: Feature9Img,
   bgImage: Feature3BgImg,
   title: "Payment and Insurance",
   description:
    "We offer flexible payment options and work with various insurance providers for your convenience.",
   linkText: "Learn more",
   delay: "200",
  },
 ],
}

export const ctaData = {
 title: "Medysense for Medical Professionals",
 subtitle:
  "If you're a medical professional, Medysense offers you a platform to expand your reach and serve patients more efficiently. Connect with individuals in your vicinity and provide timely medical assistance. No more waiting for patients in your clinic; you can go to them directly.",
 image: TwoPhones,
 btn1: GoogleBtn,
 btn2: AppleBtn,
}

export const footerData = {
 logo: LogoV2,
 list: [
  {
   id: 1,
   name: "About",
   href: "#",
  },
  {
   id: 2,
   name: "Features",
   href: "#",
  },
  {
   id: 3,
   name: "Privacy",
   href: "#",
  },
  {
   id: 4,
   name: "FAQs",
   href: "#",
  },
  {
   id: 5,
   name: "Support",
   href: "#",
  },
 ],
 socialList: [
  {
   icon: <FaYoutube />,
   href: "#",
  },
  {
   icon: <FaInstagram />,
   href: "#",
  },
  {
   icon: <FaGithub />,
   href: "#",
  },
 ],
}

export const copyrightData = {
 text:
  "© Datawarehouse™, 2020. All rights reserved. Company Registration Number: 21479524.",
 icon: <BsChatDotsFill />,
}

export const faqs = [
 {
  id: 1,
  question: "What is AlleyPay?",
  answer:
   "AlleyPay is a fintech platform that enables businesses and individuals to process payments quickly and efficiently using QR codes, phone numbers, and NFC cards. It is available on the App Store and Play Store.",
 },
 {
  id: 2,
  question: "How do I sign up for AlleyPay?",
  answer:
   "You can sign up for AlleyPay by downloading the app from the App Store or Play Store, and following the prompts to create an account. You will need to provide some basic information such as your name, phone number, and email address.",
 },
 {
  id: 3,
  question: "What payment methods does AlleyPay support?",
  answer:
   "AlleyPay supports payments using QR codes, phone numbers, and cards. You can deposit from your bank account or credit/debit card to your AlleyPay account for easy payment processing.",
 },
 {
  id: 4,
  question: "Is AlleyPay secure",
  answer:
   "Yes, AlleyPay is highly secure. We use advanced encryption technologies to protect your personal and financial information, and our platform is regularly audited to ensure compliance with industry standards.",
 },
 {
  id: 5,
  question: "How do I use AlleyPay to make payments?",
  answer:
   "To make payments using AlleyPay, simply open the app and select the payment method you prefer. You can scan QR codes or enter phone numbers to make payments, and you can also use NFC cards for contactless transactions.",
 },
 {
  id: 6,
  question:
   "How long does it take for payments to be processed using AlleyPay?",
  answer:
   "Payments using AlleyPay are processed instantly, so you can enjoy fast and seamless transactions.",
 },
 {
  id: 7,
  question: "Can I use AlleyPay to send money to other people?",
  answer:
   "Yes, you can use AlleyPay to send money to other AlleyPay users. Simply enter the recipient's phone number or scan their QR code, and enter the amount you wish to send.",
 },
 {
  id: 8,
  question: "How much does AlleyPay charge per transaction?",
  answer:
   "AlleyPay charges a N10 transaction fee for payments processed through the platform. You can find more information about our fees on our website.",
 },
 {
  id: 9,
  question: "What do I do if I encounter any issues with AlleyPay?",
  answer:
   "If you encounter any issues with AlleyPay, please contact our customer support team via email or phone. We are available 24/7 to assist you with any questions or concerns you may have.",
 },
 {
  id: 10,
  question: "How can I order for an NFC card?",
  answer:
   'To order an NFC card on AlleyPay, please follow these steps: Open the AlleyPay app on your mobile device. Tap on the "Card" icon and select "Request Card". Enter your shipping address and select your preferred payment method. Review your order and tap "Confirm" to complete the purchase. Once your order is processed, your NFC card will be shipped to the address you provided. You can then activate the card by following the instructions provided in the package. Once activated, you can use the NFC card to make contactless payments at any merchant that accepts NFC payments.',
 },
 {
  id: 11,
  question: "Is AlleyPay for business owners only?",
  answer:
   "No, AlleyPay is not only for business owners. It is a fintech platform that is available to both individuals and businesses. Anyone can download the AlleyPay app and create an account to process payments using QR codes, phone numbers, and NFC cards. Whether you are a business owner looking for a reliable payment processing solution, or an individual looking for a fast and secure way to make payments, AlleyPay is a great option for you.",
 },
]
