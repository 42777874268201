import React from "react"

//import footer data
import { footerData } from "../data"

// import components
import Copyright from "./Copyright"
import Nav from "./Nav"

import Logo from "../assets/img/logo.png"
import AppleBtn from "../assets/AppleBtn.png"
import GoogleBtn from "../assets/GoogleBtn.png"

const Footer = () => {
 // destructure footer data
 const { list } = footerData
 return (
  <footer className="h-fit">
   <section className=" md:w-[1216px] container mx-auto flex justify-between lg:flex-row gap-[20px] flex-col lg:h-[268px] align-middle items-center  lg:pb-8  md:pb-[30px] mt-[6vh] pb-[5vh] w-[100%] lg:w-[90%] xl:w-[87%] 2xl:w-[84.4%]">
    <div className="flex flex-col gap-[20px] font-bold ">
     <img
      className=" w-[100px] h-[22px] lg:w-[192px] lg:h-full"
      src={Logo}
      alt=""
     />
     <Nav />
     <Copyright />
    </div>
    {/* Right Section  */}
    <div className="lg:flex lg:flex-col hidden gap-[20px] lg:mr-[4vh]">
     <h3 className="text-black  text-[18px] font-bold"> Get the App</h3>
     <div className="flex flex-col gap-[16px]">
      <button>
       <a href="">
        <img className="h-[40px] w-[135px]" src={AppleBtn} alt="" />
       </a>
      </button>
      <button>
       <a href="">
        <img className="h-[40px] w-[135px]" src={GoogleBtn} alt="" />
       </a>
      </button>
     </div>
    </div>
   </section>
  </footer>
 )
}

export default Footer
