import React, { useRef } from "react"

// import features data
import { featuresData } from "../data"

//import icons
import { BsArrowRight } from "react-icons/bs"

const Features = () => {
 //destructure features data
 const { title, subtitle, list } = featuresData
 const featuresRef = useRef(null)

 return (
  <section
   id="#features"
   className="mx-auto lg:py-[96px] xl:py-[96px] lg:mb-0 mb-[20px] w-[100%] lg:w-[90%] xl:w-[87%] 2xl:w-[84.4%] mt-[40vh] md:mt-0"
  >
   <div className="container mx-auto text-center " ref={featuresRef}>
    {/* text */}
    <div>
     <h2 className="h2 justify-center flex leading-[60px] text-black pt-[96px] pb-[25px] ">
      {title}
     </h2>
     <p
      className="lead md:w-[800px] text-[16px] font-normal px-5 mx-auto text-center text-black"
      data-aos="fade-up"
      data-aos-delay="200"
     >
      {subtitle}
     </p>
    </div>
    {/* feature list */}
    <div className="grid grid-cols-1 xl:grid-cols-3 text-center mt-[64px] gap-y-[64px]">
     {list.map((feature, index) => {
      // destructure feature
      const { image, bgImage, title, description, linkText, delay } = feature
      // feature item
      return (
       <div
        className="w-full max-w-[530px] h-[224px] relative flex flex-col items-center justify-center xl:justify-start mx-auto "
        key={index}
        data-aos="zoom-in"
        data-aos-offset="100"
        data-aos-delay={delay}
       >
        {/* icon image */}
        <div
         className="max-w-[100px] xl:max-w-[100px] h-[80px] flex"
         data-aos="zoom-in-right"
         data-aos-delay={delay}
        >
         <img src={image} alt="" />
        </div>
        {/* text */}
        <div className="max-w-[362px] mt-[10px] ">
         <h3 className="text-[30px] leading-[40px] text-black font-bold whitespace-nowrap">
          {title}
         </h3>
         <p className="font-normal mt-[2px] text-[16px] leading-[24px] h-[72px]  text-center text-black p-4">
          {description}
         </p>
        </div>
       </div>
      )
     })}
    </div>
   </div>
  </section>
 )
}

export default Features
