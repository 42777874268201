import React from "react"

const Copyright = () => {
 return (
  <div className="text-black font-bold">
   ©️ 2023 Medysense LTD. All rights reserved
  </div>
 )
}

export default Copyright
