import React, { useState } from "react"
import Logo from "../assets/img/logo.png"
import Nav from "../components/Nav"
import NavMobile from "../components/NavMobile"
import { FaBars, FaTwitter } from "react-icons/fa"

const Header = () => {
 const [navMobile, setNavMobile] = useState(false)

 return (
  <header className="w-full">
   <div className="flex items-center justify-between relative w-[100%] lg:w-[90%] xl:w-[87%] 2xl:w-[84.4%] mx-auto px-9 py-4">
    <div className="flex items-center gap-x-20 lg:gap-x-120">
     <img className="h-[29px] w-[137px]" src={Logo} alt="" />
     <div className="hidden lg:flex">
      <Nav />
     </div>
    </div>

    <div
     className={`${
      navMobile ? "max-h-52 z-50" : "max-h-0"
     } lg:hidden absolute top-24 w-full left-0 right-0 bg-[#3359E2] font-bold rounded transition-all overflow-hidden`}
    >
     <NavMobile />
    </div>

    <button className="hidden md:flex items-center mt-2 h-[48px] rounded-[30px] w-[150px] font-bold gap-x-2 whitespace-nowrap transition bg-[#3359E2] justify-center text-white hover:bg-white hover:text-[#3359E2] rounded-30">
     <FaTwitter className="w-[27.17px] h-[21.31px]" />
     Follow Us
    </button>

    <button
     onClick={() => setNavMobile(!navMobile)}
     className="lg:hidden text-2xl text-primary cursor-pointer"
    >
     <FaBars />
    </button>
   </div>
  </header>
 )
}

export default Header
