import React from "react"
import { ctaData } from "../data"
import { BsArrowRight } from "react-icons/bs"

const CtaSection = () => {
 // destructure cta data
 const { title, subtitle, btn1, btn2, image } = ctaData

 return (
  <section
   className="bg-[#3359E2] xl:h-[526px] lg:h-[433px] pt-[5vh] md:pt-0 flex items-center justify-center relative"
   data-aos="fade-up"
  >
   <div className="container mx-auto w-[100%] md:w-[50%]  lg:w-[90%] xl:w-[87%] 2xl:w-[84.4%] flex flex-col lg:flex-row lg:justify-between lg:items-center h-full">
    {/* text */}
    <div className="text-center lg:text-left lg:w-full">
     <h1
      className="max-w-[700px] lg:h-[120px] md:h-[80px]  text-white font-bold xl:text-[48px] lg:text-[35px] text-[25px] md:leading-[60px]"
      data-aos="fade-down"
      aos-delay-delay="400"
     >
      {title}
     </h1>
     <p
      className="lead text-white text-[18px] leading-[28px] mt-[10px] font-normal max-w-[592px] text-light"
      data-aos="fade-down"
      aos-delay-delay="500"
     >
      {subtitle}
     </p>
     <div className="flex flex-col lg:mt-[2vh] xl:mt-[48px] h-full">
      <p className="text-white mt-[10px] font-bold">Get the App</p>
      <div className="flex flex-row mt-[8px] mb-[20px] md:mx-auto lg:m-0 justify-center lg:justify-start">
       <button className="w-[135px] h-[40px]">
        <a href="" data-aos="fade-down" aos-delay-delay="600">
         <img src={btn1} alt="Google Play" />
        </a>
       </button>
       <button className="w-[135px] h-[40px] ml-[12px]">
        <a href="" data-aos="fade-down" aos-delay-delay="600">
         <img src={btn2} alt="Apple Store" />
        </a>
       </button>
      </div>
     </div>
     {/* image */}
    </div>
    <div className="hidden relative lg:block lg:w-full">
     <img className="h-full lg:w-[558px] ml-[7vh]" src={image} alt="" />
    </div>
   </div>
  </section>
 )
}

export default CtaSection
