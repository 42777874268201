import React from "react"
import { Link as ScrollLink } from "react-scroll"
import { navigationData } from "../data"

const Nav = () => {
 return (
  <nav>
   <ul className="flex gap-x-8">
    {navigationData.map((item, index) => (
     <li className="hover:cursor-pointer" key={index}>
      {item.isExternal ? (
       <a
        href={item.href}
        target="_blank"
        rel="noopener noreferrer"
        className="text-black font-normal"
       >
        {item.name}
       </a>
      ) : (
       <ScrollLink
        to={item.href}
        smooth={true}
        duration={500}
        className="text-black font-normal"
       >
        {item.name}
       </ScrollLink>
      )}
     </li>
    ))}
   </ul>
  </nav>
 )
}

export default Nav
