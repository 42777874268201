import React from "react"

// import animate on scroll
import Aos from "aos"
import "aos/dist/aos.css"

import { Element, Link } from "react-scroll"
import { useRef } from "react"

// import components
import Hero from "./components/Hero"
import Features from "./components/Features"
import CtaSection from "./components/CtaSection"
import Footer from "./components/Footer"

import FAQs from "./Pages/Faqs"

const App = () => {
 const services = useRef(null)

 // aos initialization
 Aos.init({
  duration: 1800,
  offset: 0,
 })

 const scrollToSection = (elementRef) => {
  window.scrollTo({
   top: elementRef.current.offsetTop,
   behaviour: "smooth",
  })
 }

 return (
  <div className="overflow-hidden !scroll-smooth focus:scroll-auto">
   <Hero />
   <Features />
   <CtaSection />
   <Footer />
  </div>
  // <FAQs />
 )
}

export default App
