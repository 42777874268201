import React from "react"

//import Hero data
import { heroData } from "../data"

//import Components
import Header from "../components/Header"

import AppleBtn from "../assets/AppleBtn.png"
import GoogleBtn from "../assets/GoogleBtn.png"

const Hero = () => {
 // destructure hero data
 const { title, subtitle, btnText, image } = heroData
 return (
  <section className=" h-[500px] mb-[20vh] md:mb-0 md:h-[866px] 2xl:h-[1024px] 2xl:min-w-[1600px] xl:min-w-[1440px] lg:min-w-[1024px] md:min-w-[768px]">
   <Header />

   <section class="w-full h-full md:bg-hero  bg-white md:bg-blue-100 bg-cover bg-center relative bg-no-repeat">
    <div className="w-[83%] mx-auto h-full p-0 relative">
     <button className="text-black text-left font-normal pl-[20px] mx-auto bg-[#E5E7EB] rounded-2xl lg:h-[28px] lg:w-[275px] h-[40px] w-[300px] my-[22px]">
      Health care at your comfort zone
     </button>

     <div className="flex flex-col lg:flex-row h-[80vh]">
      {/* text */}
      <div className="text-center xl:text-left xl:w-[648px] xl:absolute md:pr-4 mt-7 lg:w-[40vh]">
       <h1
        className="h1 lg:w-[648px] w-screen xl:text-[58px] leading-[72px] text-center md:text-left pr-[12vh] md:pr-0 lg:mt-[70px] text-black"
        data-aos="fade-down"
        aos-delay-delay="400"
       >
        <p>Find and Book </p>
        <p className="lg:whitespace-nowrap">
         Medical<span className="text-[#3359E2]"> Professionals</span>
        </p>
       </h1>
       <p
        className="lead md:text-start xl:max-w-[648px] text-[18px] text-center text-black xl:w-[640px] lg:w-[400px] mt-[16px]"
        data-aos="fade-down"
        aos-delay-delay="500"
       >
        {subtitle}
       </p>
       <div className="flex flex-row gap-[16px] mt-[32px] items-center md:items-start justify-center md:justify-start">
        <button className="hover:cursor-pointer">
         <img
          className="md:h-[52px] w-[135px] h-[40px] md:w-[180px]"
          src={AppleBtn}
          alt=""
         />
        </button>

        <button className="hover:cursor-pointer">
         <img
          className="md:h-[52px] w-[135px] h-[40px] md:w-[180px]"
          src={GoogleBtn}
          alt=""
         />
        </button>
       </div>
       {/* image */}
      </div>
      <div
       className="xl:absolute h-[680px] xl:top-[19vh] xl:right-1 xl:w-[700px] lg:w-[600px] lg:top-[28vh] lg:right-[-90px] lg:absolute lg:block sm:hidden hidden"
       //  data-aos="fade-up"
       //  aos-delay-delay="400"
      >
       <img className="" src={image} alt="" />
      </div>
     </div>
    </div>
   </section>
  </section>
 )
}

export default Hero
